<template>
  <div style="opacity: 0.6"><h3>敬请期待......</h3></div>
</template>
<script>
  export default {
    name: 'Examination'
  }
</script>

<style scoped>
</style>
